// Youssef Selkani
// 2024

import React, { Component } from 'react'
import '../App.css'
import Title from '../components/Title'
import { Button, Container, Segment, Grid, Divider, Image, Icon } from "semantic-ui-react";

class Home extends Component {
  render() {
    return (
      <div className='main'>
        <div className='banner' />
        <Title page='Home' />
        <div>
          <Container>
            <div className='white center mb'>
              <h1 style={{ fontSize: '3em' }}>Welcome</h1>
            </div>
            <Segment inverted basic fluid padded='very'>
              <Grid fluid stackable verticalAlign='middle'>
                <Grid.Column width={8}>
                  <h3>Singer - Songwriter | Music Producer</h3>
                  <Divider />
                  <p>
                    With a keen ear for melodies and rhythms, I craft immersive sonic experiences that captivate listeners.
                    Whether it's producing tracks for other artists or creating my own music, I thrive on pushing creative boundaries and exploring diverse genres.
                  </p>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Image bordered centered src={require('../assets/hero.jpeg')} size='large' style={{ borderRadius: 14 }} />
                </Grid.Column>
              </Grid>
            </Segment>

            <Divider hidden />

            <Grid inverted padded divided fluid stackable verticalAlign='middle'>
              <Grid.Column width={4}>
                <Button fluid target="_blank" href='https://open.spotify.com/artist/718iGjUoclBncjXYwI7cZL?si=JI5bxc5fRva16WsXDOfVrw' color='green'>
                  <Icon name='spotify' /> Spotify
                </Button>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button fluid target="_blank" href='https://twitter.com/byjosephhover' color='twitter'>
                  <Icon name='twitter' /> Twitter
                </Button>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button fluid target="_blank" href='https://www.youtube.com/channel/UCBW_mQtvFCVuNqIvLz3_eOA' color='youtube'>
                  <Icon name='youtube' /> YouTube
                </Button>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button fluid target="_blank" href='https://www.instagram.com/byjosephhover/' color='black'>
                  <Icon name='instagram' /> Instagram
                </Button>
              </Grid.Column>
              <Grid.Column width={8}>
                <Button fluid target="_blank" href='https://soundcloud.com/josephhover' color='gray'>
                  <Image centered src={require('../assets/soundcloud.png')} size='tiny' />
                </Button>
              </Grid.Column>
              <Grid.Column width={8}>
                <Button fluid target="_blank" href='https://www.tiktok.com/@joseph.l.hover'>
                  <Image centered src={require('../assets/tiktok.png')} size='small' />
                </Button>

              </Grid.Column>
            </Grid>



            {/* <div className="content" dangerouslySetInnerHTML={{__html: '<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1795028980&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/josephhover" title="Joseph Hover" target="_blank" style="color: #cccccc; text-decoration: none;">Joseph Hover</a> · <a href="https://soundcloud.com/josephhover/free-dave-east-x-meek-mill-x-joyner-lucas-type-beat-trigger-2024" title="[Free] Dave East x Meek Mill x Joyner Lucas Type Beat - &quot;Trigger&quot; (2024)" target="_blank" style="color: #cccccc; text-decoration: none;">[Free] Dave East x Meek Mill x Joyner Lucas Type Beat - &quot;Trigger&quot; (2024)</a></div>'}}></div> */}

            <Segment padded='very'>
              <h2>Buy My Beats</h2>
              <p>
                <li>Beatstars: <a target="_blank" href='https://www.beatstars.com/jhoverprod' >https://www.beatstars.com/jhoverprod</a></li>
                <li>Airbit: <a target="_blank" href='https://airbit.com/jhoverprod' >https://airbit.com/jhoverprod</a></li>
                <li>Traktrain: <a target="_blank" href='https://traktrain.com/jhoverprod' >https://traktrain.com/jhoverprod</a></li>
              </p>
            </Segment>

            <center className='mt'>
              <Button style={{ paddingLeft: '5vh', paddingRight: '5vh' }} basic size='big' href='/contact' color='black'>
                Contact: jhoverprod@gmail.com
              </Button>
            </center>
          </Container>
        </div >
      </div>
    )
  }
}

export default Home
