// Youssef Selkani
// 2022

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class Title extends Component {
    render() {
        let { page } = this.props
        return (
            <Helmet>
                <title>{page + ' | Joseph Hover Official Website'}</title>
            </Helmet>
        )
    }
}
