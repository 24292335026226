// Youssef Selkani
// 2022

import React, { Component } from 'react'
import '../../App.css'
import { Container, Grid, List, Segment } from 'semantic-ui-react'

export default class Footer extends Component {
  render() {
    return (
      <div>
        <Container>
          <center style={{padding: '5vh'}}>
            <p>
              Copyright © 2024 Joseph Hover | All Rights Reserved
            </p>
          </center>
        </Container>

      </div>
    )
  }
}