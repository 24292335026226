// Youssef Selkani
// 2024

import React, { Component } from "react";
import '../../App.css'
import { Container, Divider, Message } from "semantic-ui-react";
import Title from '../../components/Title'
import ContactForm from '../../components/ContactForm'

export default class Pro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            subject: '',
            msg: ''
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    sendMessage = () => {
        console.log('sendMessage :');
        let { email, subject, msg } = this.state;
        if (email === '' || msg === '') {
            console.log('missing :');

            this.setState({ error: 'Missing inputs' })
        } else {
            console.log('ok :');

            this.setState({ loading: true, error: null });
            fetch("https://variablefrequencystudios.herokuapp.com/api/v1/contact", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    subject: subject,
                    msg: msg,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((obj) => {
                    if (obj.status === 200) {
                        this.setState({ success: true, loading: false })
                    } else {
                        this.setState({ error: '500 - Sending messge failed. Please try again.', loading: false })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ error: error.message, loading: false });
                });
        }
    };

    render() {
        return (
            <div className='main'>
                <div className='banner' />
                <Title page={'Contact'} />
                <Container className="master">
                    <div className="mb center white">
                        <h2>Get in touch</h2>
                    </div>
                <center>
                <Message compact error>Under maintenance</Message>
                </center>
                <Divider hidden />
                    <ContactForm
                        handleChange={this.handleChange}
                        sendMessage={this.sendMessage}
                        {...this.state}
                    />
                </Container>
            </div>
        );
    }
}
