// Youssef Selkani
// 2022

import React, { Component } from "react";
import '../../App.css'
import { Container, Menu, Icon } from "semantic-ui-react";

export default class NavBar extends Component {
  render() {
    return (
      <Menu borderless className="navigationbar">
        <Container>
          <Menu.Item header href='/'>
          Joseph Hover
          </Menu.Item>

          <Menu.Item
            position="right"
            href='/contact'>
            <Icon name="mail outline" color="black" />
            Contact
          </Menu.Item>
        </Container>
      </Menu>
    );
  }
}
