// Youssef Selkani
// 2022

import React, { Component } from 'react'
import '../App.css'
import { Message } from 'semantic-ui-react'

export default class Error extends Component {
  render () {
    let { error } = this.props
    return (
      <Message style={{backgroundColor: 'white', color: 'red'}} className='mtb center' >
        Error: {error}
      </Message>
    )
  }
}
