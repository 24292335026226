// Youssef Selkani
// 2022

import React, { Component } from 'react'
import '../App.css'
import { Form, Button, Icon, Message } from 'semantic-ui-react'
import Error from "./Error";

export default class ContactForm extends Component {
    render() {
        let { error, loading, email, subject, msg, success } = this.props
        return (
            <center>
                <Form style={{ maxWidth: '400px' }}>
                    {success && <Message positive size='small'>Message sent successfully. We'll get back to you soon.</Message>}
                    <Form.Input
                        onChange={this.props.handleChange}
                        placeholder='Email address'
                        name='email'
                        value={email}
                    />
                    <Form.Input
                        onChange={this.props.handleChange}
                        placeholder='Subject'
                        name='subject'
                        value={subject}
                    />
                    <Form.TextArea
                        style={{ minHeight: 200 }}
                        onChange={this.props.handleChange}
                        placeholder='Message'
                        name='msg'
                        value={msg}
                    />
                    <Button
                        disabled={loading}
                        loading={loading}
                        fluid
                        icon
                        labelPosition='left'
                        onClick={this.props.sendMessage}
                        color='black'>
                        <Icon name='send' />
                        Send
                    </Button>
                    {error && <Error {...this.state} />}
                </Form>
            </center>
        )
    }
}

