// Youssef Selkani
// 2022

import { BrowserRouter as Router, Route } from "react-router-dom";
import { Sidebar, Responsive } from "semantic-ui-react";
import { createBrowserHistory } from "history";
import React, { Component } from "react";
import "./App.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 

import Home from "./routes/Home";
import Contact from "./routes/Main/Contact";

import NavBar from "./components/Navigation/NavBar";
import Footer from './components/Navigation/Footer'

const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.state = {
      error: null,
      loading: true,
      visible: false,
      readonly: false,
    };
  }

  handleHideClick = () => this.setState({ visible: !this.state.visible });
  handleShowClick = () => this.setState({ visible: !this.state.visible });
  handleSidebarHide = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
  };

  handleOnUpdate = (e, { width }) => this.setState({ width });

  render() {
    return (
      <Router history={history}>
        <NavBar
          {...this.state}
          handleShowClick={this.handleShowClick.bind(this)}
          handleHideClick={this.handleHideClick.bind(this)}
        />
        <Responsive fireOnMount onUpdate={this.handleOnUpdate}>
          <Route path="/" exact render={() => <Home {...this.state} />} />
          <Route path="/contact" component={Contact} />
          <Footer {...this.state} />
        </Responsive>
      </Router>
    );
  }
}

export default App;
